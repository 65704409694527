import React from 'react'
// import {useStaticQuery, graphql} from 'gatsby'
import PortableText from '../serializers/portableText'
import Container from '../container'
import styles from './page.module.css'

const Page = ({title, _rawBody}) => {
  // const data = useStaticQuery(graphql`
  //   {
  //     site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
  //       title
  //     }
  //   }
  // `)

  return (
    <>
      <article className={styles.root}>
        <Container>
          <div className={styles.pageTitleWrapper}>
            <h1 className={styles.pageTitle}>{title}</h1>
          </div>
          <div className={styles.mainContent}>
            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>
        </Container>
      </article>
    </>
  )
}
export default Page
